const React = require('react')
const ReactDOM = require('react-dom/client')
const ErrorBoundary = require('./src/components/ErrorBoundary').default

require('@fontsource/inter')
require('./src/themes/fonts.css')
const Sentry = require('@sentry/gatsby')

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [],
  tracesSampleRate: 1.0,
})

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)

    root.render(element)
  }
}

exports.wrapPageElement = ({ element }) => {
  const i18nextProvider = element
  const i18nextProviderProps = i18nextProvider.props
  const renderedPage = i18nextProviderProps.children

  const wrapperElement = React.cloneElement(
    i18nextProvider,
    i18nextProviderProps,
    renderedPage,
  )

  return <ErrorBoundary>{wrapperElement}</ErrorBoundary> //Wrapping each page with cloned Provider to make sure pages will not lost i18Next Context and Sentry ErrorBoundary
}

exports.shouldUpdateScroll = ({ prevRouterProps }) => {
  return prevRouterProps ? [0, 0] : false
}
